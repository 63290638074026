import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mui+material-nextjs@6.1.6_@emotion+cache@11.13.1_@emotion+react@11.13.3_@types+react@19.0.1__ct22kxte7vmfihmaqwjas33da4/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager", webpackExports: ["ViewTransitions"] */ "/vercel/path0/node_modules/.pnpm/next-view-transitions@0.3.4_next@15.1.1-canary.0_@babel+core@7.26.0_@opentelemetry+api@1.9.0__blbu6uxgx67hrxredfbf7hyazq/node_modules/next-view-transitions/dist/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["AppTheme"] */ "/vercel/path0/src/domain/app/app-theme.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BaseLayout"] */ "/vercel/path0/src/domain/app/base-layout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Translation"] */ "/vercel/path0/src/domain/app/intl/translation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StoreProvider"] */ "/vercel/path0/src/domain/app/store/store-provider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/domain/shared/design-system/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["PreviousPathnameProvider"] */ "/vercel/path0/src/domain/shared/lib/use-previous-path.tsx");
