"use client";

import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Inter } from "next/font/google";

const inter = Inter({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin", "cyrillic"],
});

const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "var(--vui-color-surface)",
      paper: "var(--vui-color-surface)",
    },
  },
  typography: {
    fontFamily: inter.style.fontFamily,
  },
  cssVariables: true,
});

export function AppTheme({ children }: React.PropsWithChildren) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
